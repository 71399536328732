import React, { useEffect, useState } from 'react';
import { Linking, View, Text, Pressable, StyleSheet } from 'react-native';
import config from '../config.json';
const { WEBSITE_URL, POSTS_ENDPOINT } = config;

const MediaReleases = () => {
    const [releases, setReleases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMediaReleases = async () => {
            try {
                let simplifiedData = [];
                const response = await fetch(WEBSITE_URL + POSTS_ENDPOINT);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data && data.length) {
                    data.forEach(item => {
                        let content = item.excerpt.rendered.replace('<p>', '').replace('</p>', '').replace('[&hellip;]', '...');
                        let obj = {
                            id: item.id,
                            link: item.link,
                            date: item.date,
                            title: item.title.rendered,
                            excerpt: content,
                            status: item.status,
                        };
                        simplifiedData.push(obj);
                    });
                    if (simplifiedData.length) {
                        setReleases(simplifiedData);
                    }
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMediaReleases();
    }, []);

    if (loading) {
        return <Text style={styles.baseText}>{config.content.page.media.load}</Text>;
    }

    if (error) {
        return <Text style={styles.baseText}>Error: {error}</Text>;
    }

    function touchMediaCard(link) {
        if (link) {
            Linking.openURL(link);
        }
    }

    return (
        <div className="media-releases">
            {releases.length > 0 ? (
                releases.map((release) => (
                    <Pressable key={release.link} style={styles.mediaCard} onPress={() => {
                        touchMediaCard(release.link)
                    }}>
                        <Text style={styles.mediaCardTitle}>{release.title}</Text>
                        <Text style={styles.mediaCardExcerpt}>{release.excerpt}</Text>
                        <Text style={styles.mediaCardButton}>{config.content.page.media.more}</Text>
                    </Pressable>
                ))
            ) : (
                <p style={styles.baseText}>{config.content.page.media.none}</p>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    baseText: {
        color: '#FFFFFF',
        marginBlock: '1em',
    },
    mediaCard: {
        backgroundColor: '#D5D5D5',
        color: '#000000',
        marginBlock: '1em',
        padding: '1rem',
        paddingRight: '15%',
    },
    mediaCardTitle: {
        color: '#6981B2',
        fontSize: '1.125rem',
        marginBlock: '0.5rem',
    },
    mediaCardExcerpt: {
        color: '#002059',
        fontSize: '0.875rem',
        marginBottom: '1rem',
    },
    mediaCardButton: {
        border: '1px solid #002059',
        display: 'inline',
        padding: '0.75rem',
        width: '6rem',
    },
});

export default MediaReleases;
