import { StatusBar } from 'expo-status-bar';
import { View, Text, StyleSheet } from 'react-native';
import config from '../config.json';
import MediaReleases from '../components/MediaReleases';

export default function SplashScreen({navigation}) {
    return (
        <View style={styles.container}>
            <StatusBar style="auto" />
            <Text style={styles.headline}>{config.content.page.media.title}</Text>
            <Text style={styles.baseText}>{config.content.page.media.content}</Text>
            <MediaReleases />
        </View>
    );
}

const styles = StyleSheet.create({
    baseText: {
        fontFamily: '"Barlow", Barlow, Helvetica, Roboto, Arial, sans-serif',
        color: '#FFFFFF',
        marginBlock: '1em',
    },
    container: {
        flex: 1,
        backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
        alignItems: 'left', // Left <-> Right
        justifyContent: 'top', // Top <-> Bottom
        fontSize: '1rem',
        lineHeight: '1.6',
        padding: '1.5rem',
        paddingInline: '2rem',
    },
    headline: {
        color: '#ffffff',
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBlock: '1.5em',
        marginBottom: '1rem',
    },
    buttons: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBlock: '2rem',
        width: 'calc(100% - 0rem)',
    },
    button: {
        backgroundColor: '#26cfff',
        borderRadius: 3,
        color: '#00163d',
        display: 'inline-block',
        fontWeight: '500',
        marginBlock: '1rem',
        paddingBlock: '1rem',
        paddingInline: '2rem',
        textAlign: 'center',
        maxWidth: '20rem',
        width: '50%',
    },
    spacer: {
        margin: 'auto',
    },
});
