import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { storeValue, getValue, deleteValue } from '../Storage';
import * as API from '../Api';
import config from '../config.json';
const { WEBSITE_URL, LOGO_URL } = config;

export default function SplashScreen({navigation}) {
    useEffect(() => {
        const checkUserStatus = async () => {
            // Retrieve the stored values
            const username = await getValue('username');
            const password = await getValue('password');

            // Simple check to see if user data exists
            if (username && password) {
                const isValid = await API.validateLogin({username: username, password: password});
                if (isValid && isValid.status > 0) {
                    // Store the member information from the API.
                    storeValue('member', isValid?.data || {});
                    navigation.replace('Account');
                    return;
                }
                // User has likely been logged out - delete saved passwords and member info.
                deleteValue('password');
                deleteValue('member');
            }
            // If not valid or not present, navigate to Login - may need to differentiate when offline.
            navigation.replace('Login');
        };

        checkUserStatus();
    }, [navigation]);

    return (
        <View style={styles.container}>
            <StatusBar style="auto" />
            <Image source={{uri: WEBSITE_URL + LOGO_URL}} style={{width: 200, height: 45, marginBottom: '3rem'}} />
            <Text style={styles.hidden}>{config.content.title}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
    color: '#FFFFFF',
    lineHeight: '1.6'
  },
  hidden: {
    display: 'none'
  }
});
