import React from 'react';
import { Image, Pressable, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const HomeButton = () => {
    const navigation = useNavigation();

    const viewHomePage = () => {
        navigation.replace('Home');
    };

    return (
        <Pressable onPress={viewHomePage} style={styles.button}>
            <Image source={require('../media/icon-back.svg')} style={styles.icon} />
        </Pressable>
    );
};

export default HomeButton;

const styles = StyleSheet.create({
    button: {
        border: 0,
        borderRadius: '3px',
        fontWeight: '500',
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    icon: {
        height: '2rem',
        width: '2rem',
    },
});
