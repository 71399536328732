import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StyleSheet } from 'react-native';
import config from './config.json';
import { storeValue, getValue, deleteValue } from './Storage';
import AccountScreen from './screens/AccountScreen';
import HomeScreen from './screens/HomeScreen';
import SplashScreen from './screens/SplashScreen';
import LoginScreen from './screens/LoginScreen';
import LogoutScreen from './screens/LogoutScreen';
import CardScreen from './screens/CardScreen';
import ConfigScreen from './screens/ConfigScreen';
import NotificationButton from './components/NotificationButton';
import SettingsButton from './components/SettingsButton';
import BackButton from './components/BackButton';
import CloseButton from './components/CloseButton';
import HomeButton from './components/HomeButton';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';

const Stack = createStackNavigator();

function App() {
    const [showLogoutButton, setShowLogoutButton] = useState(false);

    useEffect(() => {
        const checkUserStatus = async () => {
            const username = await getValue('username');
            const password = await getValue('password');

            if (username && password) {
                setShowLogoutButton(true);
            }
        };

        checkUserStatus();
    }, [navigation]);

    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName="Home">
                <Stack.Group screenOptions={{
                    headerTintColor: '#fff',
                    headerStyle: styles.header,
                }} >
                    <Stack.Screen name="Home" component={HomeScreen} options={{
                        title: config.content.title,
                        headerLeft: () => {
                            return showLogoutButton ? <LogoutButton /> : '';
                        },
                        headerRight: () => <LoginButton />,
                    }} />

                    <Stack.Screen name="Account" component={AccountScreen} options={{
                        title: 'Account',
                        headerLeft: () => <HomeButton />,
                        headerRight: () => <SettingsButton />,
                    }} />

                    <Stack.Screen name="Load" component={SplashScreen} options={{
                        headerShown: false,
                    }} />

                    <Stack.Screen name="Login" component={LoginScreen} options={{
                        headerShown: false,
                        headerLeft: () => <BackButton />,
                    }} />

                    <Stack.Screen name="Logout" component={LogoutScreen} options={{
                        headerLeft: () => <CloseButton />,
                    }} />

                    <Stack.Screen name="Card" component={CardScreen} options={{
                        title: 'Member Card',
                        headerLeft: () => <BackButton />,
                    }} />

                    <Stack.Screen name="Settings" component={ConfigScreen} options={{
                        title: 'Settings',
                        headerLeft: () => <BackButton />,
                        headerRight: () => <NotificationButton />,
                    }} />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default App;

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#000D23',
        borderWidth: 0,
    }
});


