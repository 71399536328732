import React from 'react';
import { StyleSheet, Text, Image, Pressable, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { useNavigation } from '@react-navigation/native';
import { deleteValue, getValue } from '../Storage';
import * as API from '../Api';
import config from '../config.json';

export default function LogoutScreen({ navigation }) {
    const confirmLogout = () => {
        getValue('username').then(username => {
            getValue('password').then(password => {
                const values = {
                    username: username,
                    password: password,
                    current: 1
                };
                API.logout(values);
                deleteValue('password');
                deleteValue('member');
            });
        });
        navigation.replace('Home');
    };

    const cancelLogout = () => {
        navigation.replace('Home');
    };

    return (
        <View style={styles.container}>
            <StatusBar style="auto" />
            <Text style={styles.baseText}>{config.content.logout.confirmation}</Text>
            <Pressable onPress={confirmLogout} style={styles.button}>
                <Text>{config.content.logout.button}</Text>
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    baseText: {
        fontFamily: '"Barlow", Barlow, Helvetica, Roboto, Arial, sans-serif',
        color: '#FFFFFF',
        fontSize: '1.5rem',
        marginBlock: '1em',
    },
    button: {
        backgroundColor: '#FFFFFF',
        border: 0,
        borderRadius: '3px',
        color: '#000000',
        fontWeight: 'bold',
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    cancel: {
        color: '#FFFFFF',
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    container: {
        flex: 1,
        backgroundImage: 'linear-gradient(0deg, #002649 -20%, #08142B 52%)',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
        color: '#FFFFFF',
        paddingInline: '1rem',
        lineHeight: '1.6',
    },
});

/*
const LogoutButton = () => {
    const navigation = useNavigation();

    const handleLogout = () => {
        getValue('username').then(username => {
            getValue('password').then(password => {
                const values = {
                    username: username,
                    password: password,
                    current: 1
                };
                API.logout(values);
                deleteValue('password');
                deleteValue('member');
            });
        });
        navigation.replace('Login');
    };

    return (
        <Pressable onPress={handleLogout} style={styles.button}>
            <Image source={require('../media/icon-logout.svg')} style={styles.icon} />
        </Pressable>
    );
};

export default LogoutButton;

const styles = StyleSheet.create({
    button: {
        border: 0,
        borderRadius: '3px',
        fontWeight: '500',
        marginHorizontal: '0.5rem',
        paddingBlock: '0.75rem',
        paddingInline: '0.75rem',
    },
    icon: {
        width: '1.5rem',
        height: '1.5rem',
    },
});
*/